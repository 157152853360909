import React from 'react'

const LeadRejectionPolicy = ({ funcNav, funcFooter }) => {
  funcNav(true)
  funcFooter(true)
  return (
    <div>
      <section class='blog-banner'>
        <div class='container-fluid'></div>
        <div class='col-md-12'></div>
      </section>
      <section class='privacy-main'>
        <div class='container'>
          <h3>LeadXchange.AI Lead Rejection Policy</h3>

<div className='row'>
            <div className='col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xll-12 '>
              <div className='privacy-inner'>
                <div className='table-responsive'>
                  <table className='table table-bordere lrpTableR'>
                    <tbody>
                      <tr>
                        <td colspan='2'>
                           Purpose:
                           <br />
                           <br />
                           The purpose of this policy is to establish clear guidelines for rejecting leads generated and delivered by LeadXchange.AI to ensure transparency, accountability, and a consistent process for both clients and internal teams.
                        </td>
                      </tr>
                      <tr>
                        <td colspan='2'>
                           1. Scope
                           <br />
                           <br />
                           This policy applies to all leads delivered by LeadXchange.AI as part of demand generation, lead nurturing, or marketing campaigns. It outlines acceptable grounds for rejection and the process to follow in case of disputes.
                        </td>
                      </tr>
                      <tr>
                        <td colspan='2'>
                           2. Valid Grounds for Lead Rejection
                           <br />
                           <br />
                           A lead may be considered invalid and subject to rejection under the following conditions:
                        </td>
                      </tr>
                      <tr>
                        <td>&nbsp;&nbsp;&nbsp; 1. Incomplete or Incorrect Information: </td>
                        <td>
                          <ul>
                            <li>The lead contains missing or inaccurate contact details (e.g., email, phone number, job title, company name).</li>
                            <li>The lead’s information does not match the provided targeting criteria.</li>
                          </ul>
                        </td>
                      </tr>
                      <tr>
                        <td>&nbsp;&nbsp;&nbsp; 2. Duplicate Leads:</td>
                        <td>
                          <ul>
                            <li>The lead is a duplicate of one already delivered by LeadXchange.AI in the same campaign.</li>
                            <li>The lead is a duplicate from the client’s existing database, provided that the client has shared their database prior to campaign initiation.</li>
                          </ul> 
                        </td>
                      </tr>
                      <tr>
                        <td>&nbsp;&nbsp;&nbsp; 3. Out of Target Audience (OTA):</td>
                        <td>
                         <ul>
                            <li>The lead falls outside the agreed-upon target audience specifications (e.g., job title, industry, geography).</li>
                          </ul>
                        </td>
                      </tr>
                      <tr>
                        <td>&nbsp;&nbsp;&nbsp; 4. Invalid or Non-Responsive Contact:</td>
                        <td>
                          <ul>
                            <li>The email address provided in the lead is invalid (e.g., bounces back).</li>
                            <li>The phone number is non-functional .</li>
                          </ul>
                        </td>
                      </tr>
                      <tr>
                        <td>&nbsp;&nbsp;&nbsp; 5. Outside Agreed Timeline:</td>
                        <td>
                          <ul>
                            <li>The lead was generated and delivered outside the agreed-upon timeline for the campaign.</li>
                          </ul>
                        </td>
                      </tr>
                      <tr>
                        <td>&nbsp;&nbsp;&nbsp; 6. Misaligned Intent or Interest:</td>
                        <td>
                          <ul>
                            <li>The lead explicitly indicates no interest in the client’s products or services during follow-up.</li>
                          </ul>
                        </td>
                      </tr>
                      <tr>
                        <td>&nbsp;&nbsp;&nbsp; 7. Unreachable & Unresponsive:</td>
                        <td>
                        A contact may be considered for rejection as "unreachable/unresponsive" only under the following conditions:
                        <br />
                          <ul>
                            <li>Minimum Outreach Attempts:
                              <ul className='lrpCircle'>
                                <li>At least 6 call attempts have been made at varied times across different days.</li>
                                <li>At least 3 email touchpoints have been sent, spaced reasonably over time.</li>
                                <li>No response has been received through any of these outreach methods.</li>
                              </ul>
                            </li>
                          </ul>
                          <ul>
                            <li>Supporting Evidence required from client:
                              <ul className='lrpCircle'>
                              <li>Detailed logs of call attempts, including timestamps and outcomes (e.g., no answer, invalid number).</li>
                              <li>Email records showing delivery without any response.</li>
                              </ul>
                            </li>
                          </ul>
                          <ul>
                            <li>Timeframe Compliance:
                              <ul className='lrpCircle'>
                              <li>All outreach attempts must occur within the mutually agreed-upon timeline for lead validation.</li>
                              </ul>
                            </li>
                          </ul>
                        </td>
                      </tr>
                      <tr>
                        <td>&nbsp;&nbsp;&nbsp; 8. No Longer with the Company</td>
                        <td>
                        Yes, a lead will be valid for rejection if the contact is confirmed to be no longer with the company. However, the following conditions and processes will apply to ensure fairness
                        </td>
                      </tr>
                      <tr>
                        <td colspan='2'>
                           3. Non-Acceptable Grounds for Lead Rejection
                           <br />
                           <br />
                           A lead will not be considered valid for rejection under the following circumstances:
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class='table-responsive'>
                  <table class='table table-bordere lrpTableR'>
                    <tbody>
                      <tr>
                        <td>&nbsp;&nbsp;&nbsp; 1. Non-Responsive to Outreach Attempts:</td>
                        <td>
                         <ul>
                            <li>Leads that do not respond to outreach efforts by the client remain valid unless they explicitly decline or are unreachable after multiple, documented attempts.</li>
                          </ul>
                        </td>
                      </tr>
                      <tr>
                        <td colspan='2'>
                        Solution: LeadXchange.AI will facilitate a triangular call in such cases. The cost for this service will be adjusted against the cost of 4 BANT leads.
                        <br />
                        <br />
                        This ensures an efficient resolution while maintaining transparency in cost adjustments.
                        </td>
                      </tr>
                      <tr>
                        <td>&nbsp;&nbsp;&nbsp; 2. Attributes Outside Targeting Criteria:</td>
                        <td>
                          <ul>
                            <li>Leads cannot be rejected based on attributes like company size, revenue, or other details unless these were explicitly defined as part of the targeting criteria in the campaign agreement.</li>
                          </ul>
                        </td>
                      </tr>
                      <tr>
                        <td>&nbsp;&nbsp;&nbsp; 3. Interest Without Conversion:</td>
                        <td>
                          <ul>
                            <li>Leads indicating interest but not converting into a sale or further opportunity remain valid, as conversion depends on factors beyond LeadXchange.AI's control.</li>
                          </ul>
                        </td>
                      </tr>
                      <tr>
                        <td>&nbsp;&nbsp;&nbsp; 4. Call-Back Requests:</td>
                        <td>
                          <ul>
                            <li>Leads requesting a call-back at a specific time remain valid unless they explicitly state disinterest or become unreachable after subsequent agreed follow-ups.</li>
                          </ul>
                        </td>
                      </tr>
                      <tr>
                        <td>&nbsp;&nbsp;&nbsp; 5. Initial Unavailability:</td>
                        <td>
                          <ul>
                            <li>Leads who answer but request a different time for discussion are still valid unless they clearly indicate disinterest or remain unresponsive after a reasonable follow-up effort.</li>
                          </ul>
                        </td>
                      </tr>
                      <tr>
                        <td>&nbsp;&nbsp;&nbsp; 6. Gatekeeper Responses:</td>
                        <td>
                          <ul>
                            <li>Leads where gatekeepers (e.g., assistants) request a follow-up or provide an alternative time are considered valid unless further attempts confirm unreachability or explicit disinterest.</li>
                          </ul>
                        </td>
                      </tr>
                      <tr>
                        <td>&nbsp;&nbsp;&nbsp; 7. Subjective Dissatisfaction:</td>
                        <td>
                          <ul>
                            <li>Leads cannot be rejected based on subjective criteria, such as dissatisfaction with their responsiveness, tone, or perceived quality, unless it directly conflicts with the agreed campaign parameters.</li>
                          </ul>
                        </td>
                      </tr>
                      <tr>
                        <td>&nbsp;&nbsp;&nbsp; 8. Leads That Request a Call Back:</td>
                        <td></td>
                      </tr>
                      <tr>
                        <td colspan='2'>
                        A lead that picks up the call and requests to be contacted at a later time will be considered a valid lead and cannot be rejected, unless specific conditions are met.
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <div class='table-responsive'>
                  <table class='table table-bordere lrpTableR'>
                    <tbody>
                      <tr>
                        <td colspan='2'>
                          a. Valid Criteria for Rejection
                        </td>
                      </tr>
                      <tr>
                        <td>A lead that requests a call-back may only be rejected if:</td>
                        <td>
                          <ul>
                           <li>The lead explicitly states disinterest during the initial conversation or follow-up attempts but if Lead express disinterest after the complete conversation cannot be rejected .</li>
                           <li>The lead does not respond or engage after multiple follow-ups (minimum 2 calls and 2 emails) at the agreed time and date. (supporting is mandatory from the client)</li>
                          </ul>
                        </td>
                      </tr>
                      <tr>
                        <td colspan='2'>A lead that picks up the call and requests to be contacted at a later time will be considered a valid lead and cannot be rejected, unless specific conditions are met.</td>
                      </tr>
                      <tr>
                        <td colspan='2'>
                          b. Non-Acceptable Grounds for Rejection
                        </td>
                      </tr>
                      <tr>
                        <td>Call-Back Requests:</td>
                        <td>
                          <ul>
                          <li>A lead that asks to be contacted at a later time remains valid as long as they have not explicitly expressed disinterest.</li>
                          <li>It is the client's responsibility to honor the agreed call-back time.</li>
                          </ul>
                        </td>
                      </tr>
                      <tr>
                        <td>Initial Unavailability:</td>
                        <td>
                          <ul>
                          <li>Leads who are initially unavailable or busy but engage during subsequent follow-ups are still valid.</li>
                          </ul>
                        </td>
                      </tr>
                      <tr>
                        <td>Gatekeeper Interactions:</td>
                        <td>
                          <ul>
                          <li>If the call is routed through a gatekeeper and the lead’s availability is confirmed for a later time, the lead remains valid.</li>
                          </ul>
                        </td>
                      </tr>
                      <tr>
                        <td colspan='2'>
                         c. Resolution Process
                        </td>
                      </tr>
                      <tr>
                        <td>If the lead fails to engage after follow-up attempts, the client must provide:</td>
                        <td>
                          <ul>
                            <li>A log of outreach attempts with timestamps.</li>
                            <li>Evidence of the lead's non-responsiveness after the agreed call-back time.</li>
                          </ul>
                        </td>
                      </tr>
                      <tr>
                        <td colspan='2'>LeadXchange.AI will review the evidence and determine the validity of the rejection.</td>
                      </tr>
                      <tr>
                        <td colspan='2'>
                          d. Final Decision
                        </td>
                      </tr>
                      <tr>
                        <td colspan='2'>
                          <ul>
                            <li>Accepted as Valid: Leads that ask to be contacted later but remain reachable and engage subsequently are not eligible for rejection.</li>
                            <li>Rejected: Leads that explicitly express disinterest or remain unresponsive after agreed follow-ups can be considered for replacement or credit.</li>
                          </ul>
                        </td>
                      </tr>
                      <tr>
                        <td colspan='2'>
                        This policy ensures fairness while encouraging both parties to make reasonable efforts to engage with potential leads.
                        </td>
                      </tr>
                      <tr>
                        <td colspan='2'>
                        Key Notes:
                        </td>
                      </tr>
                      <tr>
                        <td>Follow-Up Responsibility:</td>
                        <td>
                          <ul>
                          <li>Clients are expected to honor requests for call-backs or follow-ups as part of nurturing leads.</li>
                          </ul>
                        </td>
                      </tr>
                      <tr>
                        <td>Objective Metrics:</td>
                        <td>
                          <ul>
                          <li>Lead rejection must be based on objective, documented criteria, ensuring transparency and fairness for both parties.</li>
                          </ul>
                        </td>
                      </tr>
                      <tr>
                        <td>Evidence-Based Rejections:</td>
                        <td>
                          <ul>
                          <li>Clients must provide supporting evidence for any rejection claim, such as call logs or email records, for validation by LeadXchange.AI.</li>
                          </ul>
                        </td>
                      </tr>
                      <tr>
                        <td colspan='2'>
                        This policy ensures clarity and fosters trust by defining reasonable boundaries for lead rejection while emphasizing collaboration and accountability.
                        </td>
                      </tr>
                      <tr>
                        <td colspan='2'>
                        4. Lead Rejection Process
                        </td>
                      </tr>
                      <tr>
                        <td>&nbsp;&nbsp;&nbsp; 1. Submission of Rejected Leads:</td>
                        <td>
                          <ul>
                            <li>Rejections must be submitted within 3 working days of lead delivery.</li>
                            <li>The client must provide a detailed reason for rejection, supported by evidence (e.g., bounce-back email, recording of invalid phone number).</li>
                          </ul>
                        </td>
                      </tr>
                      <tr>
                        <td>&nbsp;&nbsp;&nbsp; 2. Review Process:</td>
                        <td>
                          <ul>
                            <li>The LeadXchange.AI team will review the rejected leads within 3 business days of submission.</li>
                            <li>Leads will be cross-verified against campaign targeting criteria, database records, and provided evidence.</li>
                          </ul>
                        </td>
                      </tr>
                      <tr>
                        <td>&nbsp;&nbsp;&nbsp; 3. Resolution:</td>
                        <td>
                          <ul>
                           <li>If a rejection is deemed valid, LeadXchange.AI will replace the rejected lead(s) at no additional cost or adjust the billing accordingly.</li>
                           <li>If a rejection is deemed invalid, the client will be informed with a detailed explanation.</li>
                          </ul>
                        </td>
                      </tr>
                      <tr>
                        <td colspan='2'>
                        5. Dispute Resolution
                        </td>
                      </tr>
                      <tr>
                        <td colspan='2'>
                          <ul>
                            <li>In case of disagreements regarding the rejection of leads, both parties will engage in a review call to resolve the matter amicably.</li>
                            <li>If no resolution is reached, the terms outlined in the service agreement will govern the final decision.</li>
                          </ul>
                        </td>
                      </tr>
                      <tr>
                        <td colspan='2'>
                        6. Policy Updates
                        <br />
                        <br />
                        LeadXchange.AI reserves the right to revise this policy periodically to reflect changes in services, industry practices, or legal requirements. Clients will be informed of updates in advance.
                        </td>
                      </tr>
                       <tr>
                        <td colspan='2'>
                       7. Contact
                       <br />
                       <br />
                        For any queries or disputes related to lead rejection, clients may reach out to their designated campaign manager or email support@leadxchange.ai.
                        <br />
                        <br />
                        This policy ensures a structured, fair, and transparent approach to lead rejections, fostering trust and accountability between LeadXchange.AI and its clients.
                        </td>
                      </tr>
                      <tr>
                        <td colspan='2'>
                          Initial Unavailability and Follow-Up Guidelines:
                          <br />
                          <br />
                          Leads who are initially unavailable or busy but respond and engage during follow-up attempts are considered valid and cannot be rejected. To ensure clarity, the following follow-up guidelines must be adhered to:
                        </td>
                      </tr>
                      <tr>
                        <td colspan='2'>
                          Follow-Up Period and Number of Attempts
                        </td>
                      </tr>
                      <tr>
                        <td>Follow-Up Period:</td>
                        <td>
                          <ul>
                           <li>The follow-up period for any lead will align with the overall campaign timeline, typically within 7 business days from the date of lead delivery.</li>
                          </ul>
                        </td>
                      </tr>
                      <tr>
                        <td>Minimum Number of Attempts:</td>
                        <td>
                          A minimum of 4 follow-up attempts must be made, including:
                          <ul>
                            <li>2 phone calls at varied times across different days.</li>
                            <li>2 email touchpoints spaced reasonably within the follow-up period.</li>
                          </ul>
                        </td>
                      </tr>
                       <tr>
                        <td>Escalation After Unresponsiveness:</td>
                        <td>
                          <ul>
                            <li>If the lead remains unresponsive after 4 follow-ups within the 7-business-day period, it may qualify for review under the rejection criteria.</li>
                          </ul>
                        </td>
                      </tr>
                      <tr>
                        <td colspan='2'>
                          Responsibilities
                        </td>
                      </tr>
                      <tr>
                        <td>Client's Responsibility:</td>
                        <td>
                          <ul>
                            <li>Ensure follow-ups are conducted within the defined period and provide documented evidence (call logs and email records) for rejection claims.</li>
                          </ul>
                        </td>
                      </tr>
                       <tr>
                        <td>LeadXchange's Responsibility:</td>
                        <td>
                          <ul>
                            <li>Replace leads that meet valid rejection criteria promptly to avoid delays in campaign delivery.</li>
                          </ul>
                        </td>
                      </tr>
                      <tr>
                        <td colspan='2'>
                          Rationale
                        </td>
                      </tr>
                      <tr>
                        <td>Balance:</td>
                        <td>
                          <ul>
                            <li>Ensures sufficient attempts to gauge lead engagement without unnecessarily prolonging campaign timelines.</li>
                          </ul>
                        </td>
                      </tr>
                       <tr>
                        <td>Fairness:</td>
                        <td>
                          <ul>
                            <li>Protects leads from premature rejection while providing a clear framework for handling unresponsive contacts.</li>
                          </ul>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default LeadRejectionPolicy
